// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@use '~inter-ui/default' with (
  $inter-font-display: swap,
  $inter-font-path: '~inter-ui/Inter (web)'
);
@use '~inter-ui/variable' with (
  $inter-font-display: swap,
  $inter-font-path: '~inter-ui/Inter (web)'
);
@include default.all;
@include variable.all;

html {
  font-family: 'Inter', 'system-ui';
}

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', 'system-ui';
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;700;900&display=swap');

@import 'margins-paddings';
@import 'custom-colors';
@import 'global-components';
@import 'custom-sizes';

// Swiper
@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';

/** Ionic CSS Variables **/
:root {
  --ion-color-neutral-50: #fafbff;
  --ion-color-neutral-100: #eff2f9;

  /** font-family **/
  --ion-font-family: 'Inter';
  --bs-body-font-family: 'Inter';

  /** primary **/
  --ion-color-primary: #c91fcd;
  --ion-color-primary-rgb: 201, 31, 205;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #f3c1f2;
  --ion-color-primary-tint: #edd4ec;

  /** secondary **/
  --ion-color-secondary: #00a5a8;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #ade5e9;
  --ion-color-secondary-tint: #e8fcfd;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #9ff3d0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #00c292;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #ade5e9;
  --ion-color-success-tint: #e8fcfd;

  /** warning **/
  --ion-color-warning: #f59e0b;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #ef4444;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b0d;

  /** dark **/
  --ion-color-dark: #0e142d;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #313850;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #eff2f9;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Shades **/
  --ion-color-shade-0: #fff;
  --ion-color-shade-100: #000;
  --ion-color-transparent: #00000000;
  --ion-color-medium-transparent: #00000040;
}
