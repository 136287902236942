.bold {
  font-size: var(--ion-font-size-x-small);
  font-weight: var(--ion-font-weight-bold);
  line-height: var(--ion-line-height-small);
  color: var(--ion-color-neutral-700);
}

.capitalize {
  text-transform: capitalize !important;
}

.dark-bg {
  background: var(--ion-color-neutral-700);
}

.page .container {
  padding: 32px 24px;
  margin-bottom: 70px;
}

.datatable-container {
  padding-bottom: 82px;
}

.page .container-overview {
  background: var(--ion-color-shade-0);
  border-radius: 24px 24px 0px 0px;
  width: 100%;
  padding: 24px 0px !important;
}

.datatable-container {
  padding-bottom: 82px;
}

todo.ion-page,
deals.ion-page,
offers.ion-page {
  justify-content: flex-start;
}

.small-text {
  font-size: var(--ion-font-size-x-small);
  font-weight: var(--ion-font-weight-regular);
  line-height: var(--ion-line-height-small);
  color: var(--ion-color-neutral-700);
  margin: 0;
  margin-bottom: 4px;
}

.segment {
  visibility: hidden;
  opacity: 0;
  display: none;
  transition: visibility 0s, opacity 0.5s linear;
}

.shown {
  visibility: visible;
  opacity: 1;
  display: block;
}

.text-center {
  text-align: center;
}

.dot-notification {
  background-color: var(--ion-color-primary-500);
  border-radius: 50%;
  height: 8px;
  margin-left: 8px;
  opacity: 0;
  width: 8px;

  &.new-notification {
    opacity: 1;
    transition-delay: 0.5s;
    transition: all 1s;
  }
}

.custom-menu-tabs {
  background: var(--ion-color-shade-0);
}

.white-icon,
.text-white {
  color: var(--ion-color-shade-0);
}

.green-icon,
.text-green {
  color: var(--ion-color-success-500);
}

.orange-icon,
.text-orange {
  color: var(--ion-color-warning-500);
}

.red-icon,
.text-red {
  color: var(--ion-color-error-600);
}

.pink-text {
  color: var(--ion-color-primary-500) !important;
}

.text-inherit {
  text-transform: inherit !important;
}

input[type='file'] {
  min-height: 100%;
  opacity: 0;
}

ngx-datatable {
  .page-count {
    display: none;
  }

  ul.pager {
    margin-right: 32px !important;
  }

  ul.pager li a {
    padding: 4px;
    color: var(--ion-color-neutral-700);
    font-size: var(--ion-font-size-small) !important;
    font-weight: var(--ion-font-weight-regular) !important;
    text-decoration: none !important;
    font-family: 'Inter';
  }

  ul.pager li.active a {
    color: var(--ion-color-primary-500);
    font-weight: var(--ion-font-weight-semi-bold) !important;
  }

  datatable-header-cell.datatable-header-cell,
  datatable-body-cell.datatable-body-cell,
  datatable-header.datatable-header {
    display: flex !important;
    align-items: center !important;
  }

  .column-center {
    justify-content: center;

    .datatable-body-cell-label {
      text-align: center;
    }
  }

  .datatable-checkbox input[type='checkbox'] {
    margin: 0 !important;
  }

  .datatable-checkbox input[type='checkbox']:before {
    width: 12px;
    height: 12px;
    border: 1.3px solid var(--ion-color-neutral-300);
    border-radius: 2px;
  }

  .datatable-body-cell-label {
    width: 100%;
  }

  .datatable-body-cell {
    border-top: 1px solid var(--ion-color-shade-0);
  }

  .table-header {
    font-size: var(--ion-font-size-xx-small);
    font-weight: var(--ion-font-weight-semi-bold);
    line-height: var(--ion-line-height-small);
    color: var(--ion-color-neutral-400);
    text-transform: uppercase;

    &.sort-active {
      color: var(--ion-color-neutral-700);
    }
  }
}

.icon-input-container {
  position: absolute;
  right: 16px;
  width: 20px;
  height: 20px;

  &.inactive i-feather {
    color: var(--ion-color-neutral-300);
  }
  & i-feather {
    width: 20px;
    height: 20px;
  }
}

.datepicker-input {
  background: var(--ion-color-neutral-100);
  border-color: var(--ion-color-neutral-100);
  border-radius: var(--ion-border-radius-small);
  border: 1px solid var(--ion-color-neutral-100);
  color: var(--ion-color-neutral-700);
  font-size: var(--ion-font-size-small);
  font-weight: var(--ion-font-weight-regular);
  height: var(--ion-height-large);
  letter-spacing: 0em;
  line-height: var(--ion-line-height-medium);
  min-height: var(--ion-height-large);
  opacity: 1 !important;
  padding: 16px;
  width: 100%;

  .date-text {
    color: var(--ion-color-neutral-700);
    font-size: var(--ion-font-size-small);
    font-weight: var(--ion-font-weight-regular);
    line-height: var(--ion-line-height-medium);
    letter-spacing: 0em;

    &.placeholder {
      color: var(--ion-color-neutral-400);
    }
  }

  &.invalid {
    border: 1px solid var(--ion-color-error-500);
    width: 100%;
  }

  i-feather {
    width: 20px;
    height: 20px;
  }

  .inactive i-feather {
    color: var(--ion-color-neutral-300);
  }
}
