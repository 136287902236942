/** Our custom colors **/
:root {
  /** Neutral **/
  --ion-color-neutral-50: #fafbff;
  --ion-color-neutral-100: #eff2f9;
  --ion-color-neutral-200: #e2e6f0;
  --ion-color-neutral-300: #c6cad9;
  --ion-color-neutral-400: #adb1be;
  --ion-color-neutral-500: #787c8f;
  --ion-color-neutral-600: #50566c;
  --ion-color-neutral-700: #313850;
  --ion-color-neutral-800: #1d233e;
  --ion-color-neutral-900: #0e142d;

  /** Primary **/
  --ion-color-primary-50: #fff8fe;
  --ion-color-primary-100: #fff3fe;
  --ion-color-primary-200: #fce7fb;
  --ion-color-primary-300: #f3c1f2;
  --ion-color-primary-300-rgb: 243, 193, 242; // rgb color for the primary 300 to use opacity element
  --ion-color-primary-400: #e191dd;
  --ion-color-primary-500: #c91fcd;
  --ion-color-primary-600: #a720b0;
  --ion-color-primary-700: #842194;
  --ion-color-primary-800: #622177;
  --ion-color-primary-900: #3f225b;

  /** Secondary **/
  --ion-color-secondary-50: #f9ffff;
  --ion-color-secondary-100: #f2feff;
  --ion-color-secondary-200: #e8fcfd;
  --ion-color-secondary-300: #ade5e9;
  --ion-color-secondary-400: #58d0d2;
  --ion-color-secondary-500: #00a5a8;
  --ion-color-secondary-600: #07848d;
  --ion-color-secondary-700: #0f6473;
  --ion-color-secondary-800: #164358;
  --ion-color-secondary-900: #1a3049;

  /** Success **/
  --ion-color-success-50: #f8fffc;
  --ion-color-success-100: #e8fff5;
  --ion-color-success-200: #dcfff0;
  --ion-color-success-300: #9ff3d0;
  --ion-color-success-400: #53e1ae;
  --ion-color-success-500: #00c292;
  --ion-color-success-600: #076b5f;
  --ion-color-success-700: #085047;
  --ion-color-success-800: #06443c;
  --ion-color-success-900: #063933;

  /** Warning **/
  --ion-color-warning-50: #fffbeb;
  --ion-color-warning-100: #fef3c7;
  --ion-color-warning-200: #fde68a;
  --ion-color-warning-300: #fcd34d;
  --ion-color-warning-400: #fbbf24;
  --ion-color-warning-500: #f59e0b;
  --ion-color-warning-600: #a67415;
  --ion-color-warning-700: #76621e;
  --ion-color-warning-800: #5f5621;
  --ion-color-warning-900: #332f1b;

  /** Error **/
  --ion-color-error-50: #fef2f2;
  --ion-color-error-100: #fee2e2;
  --ion-color-error-200: #fecaca;
  --ion-color-error-300: #fca5a5;
  --ion-color-error-400: #f87171;
  --ion-color-error-500: #ef4444;
  --ion-color-error-600: #dc2626;
  --ion-color-error-700: #b91c1c;
  --ion-color-error-800: #991b1b;
  --ion-color-error-900: #7f1d1d;

  /** Dark **/
  --ion-color-dark-50: #5f626b;
  --ion-color-dark-100: #555962;
  --ion-color-dark-200: #4c4f59;
  --ion-color-dark-300: #424651;
  --ion-color-dark-400: #424651;
  --ion-color-dark-500: #393d48;
  --ion-color-dark-600: #2f343f;
  --ion-color-dark-700: #262a36;
  --ion-color-dark-800: #1c212e;
  --ion-color-dark-900: #131825;

  /** Shades **/
  --ion-color-shade-0: #fff;
  --ion-color-shade-100: #000;
  --ion-color-transparent: #00000000;
  --ion-color-medium-transparent: #00000040;

  /** Gray **/
  --ion-color-gray-50: #f8fafc;
}
