:root {
  /** font sizes **/
  --ion-font-size-xx-small: 10px;
  --ion-font-size-x-small: 12px;
  --ion-font-size-small: 14px;
  --ion-font-size-mid-medium: 16px;
  --ion-font-size-medium: 18px;
  --ion-font-size-medium-big: 20px;
  --ion-font-size-big: 24px;
  --ion-font-size-x-big: 32px;
  --ion-font-size-xx-big: 40px;

  /** font line height **/
  --ion-line-height-small: 16px;
  --ion-line-height-medium: 24px;
  --ion-line-height-big: 32px;
  --ion-line-height-x-big: 40px;
  --ion-line-height-xx-big: 48px;

  /** font-weight **/
  --ion-font-weight-regular: 400;
  --ion-font-weight-semi-bold: 600;
  --ion-font-weight-bold: 700;

  /** border radius **/
  --ion-border-radius-x-small: 6px;
  --ion-border-radius-small: 8px;
  --ion-border-radius-medium: 16px;
  --ion-border-radius-large: 24px;
  --ion-border-radius-common: 100px;

  /** custom height/padding/margins **/
  --ion-height-ancor: 16px;
  --ion-height-xx-small: 32px;
  --ion-height-x-small: 42px;
  --ion-height-small: 48px;
  --ion-height-medium: 52px;
  --ion-height-large: 56px;
  --ion-padding-xx-small: 4px;
  --ion-padding-x-small: 8px;

  /** ionic custom sizes **/
  --bs-body-line-height: 24px;

  /** font-weight Classes **/
  .font-w-400 {
    font-weight: var(--ion-font-weight-regular) !important;
  }

  .font-w-600 {
    font-weight: var(--ion-font-weight-semi-bold) !important;
  }

  .font-w-700 {
    font-weight: var(--ion-font-weight-bold) !important;
  }

  .box-shadow-xs {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  }
}
