$breakpoints: (
  sm  : 576px,
  md  : 768px,
  lg  : 992px,
  xl  : 1200px,
);

// Width
@mixin on-view-min($breakpoint) {
  $brk: map-get($breakpoints, $breakpoint);
  @media (min-width: $brk) { @content; }
}

$breakpoints-mobile: (
  smw  : 350px,
  mdw  : 380px,
  lgw  : 400px,
  xlw  : 410px,
  smh  : 600px,
  mdh  : 700px,
  lgh  : 800px,
  xlh  : 900px,
);

// Mobile
@mixin on-view-mobile-min($breakpointh, $breakpointw) {
  $brkw: map-get($breakpoints-mobile, $breakpointw);
  $brkh: map-get($breakpoints-mobile, $breakpointh);
  @media only all and (min-width: $brkw) and (min-height: $brkh) { @content; }
}