@use 'mixins' as m;

/** paddings/margins (ALL OF THESE MARGINS
  SHOULD OVERWRITE BOOTSTRAP STYLE USING !IMPORTANT) **/
:root {
  // MARGINS
  /** Margin top **/
  .mt-0 {
    margin-top: 0 !important;
  }

  .mt-0-25 {
    margin-top: 2px !important;
  }

  .mt-0-5 {
    margin-top: 4px !important;
  }

  .mt-0-75 {
    margin-top: 6px !important;
  }

  .mt-1 {
    margin-top: 8px !important;
  }

  .mt-1-4 {
    margin-top: 10px !important;
  }

  .mt-1-5 {
    margin-top: 12px !important;
  }

  .mt-1-75 {
    margin-top: 14px !important;
  }

  .mt-2 {
    margin-top: 16px !important;
  }

  .mt-2-25 {
    margin-top: 18px !important;
  }

  .mt-2-1 {
    margin-top: 20px !important;
  }

  .mt-3 {
    margin-top: 24px !important;
  }

  .mt-3-25 {
    margin-top: 26px !important;
  }

  .mt-3-5 {
    margin-top: 28px !important;
  }

  .mt-4 {
    margin-top: 32px !important;
  }

  .mt-4-25 {
    margin-top: 34px !important;
  }

  .mt-4-5 {
    margin-top: 36px !important;
  }

  .mt-5 {
    margin-top: 40px !important;
  }

  .mt-6 {
    margin-top: 56px !important;
  }

  /** Margin bottom **/
  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mb-0-25 {
    margin-bottom: 2px !important;
  }

  .mb-0-5 {
    margin-bottom: 4px !important;
  }

  .mb-0-75 {
    margin-bottom: 6px !important;
  }

  .mb-1 {
    margin-bottom: 8px !important;
  }

  .mb-1-5 {
    margin-bottom: 12px !important;
  }

  .mb-1-2 {
    margin-bottom: 12px !important;
  }

  .mb-1-75 {
    margin-bottom: 14px !important;
  }

  .mb-2 {
    margin-bottom: 16px !important;
  }

  .mb-2-2 {
    margin-bottom: 20px !important;
  }

  .mb-2-25 {
    margin-bottom: 18px !important;
  }

  .mb-2-5 {
    margin-bottom: 20px !important;
  }

  .mb-3 {
    margin-bottom: 24px !important;
  }

  .mb-3-5 {
    margin-bottom: 28px !important;
  }

  .mb-4 {
    margin-bottom: 32px !important;
  }

  .mb-5 {
    margin-bottom: 40px !important;
  }

  .mb-6 {
    margin-bottom: 56px !important;
  }

  .ml-2 {
    margin-left: 16px !important;
  }

  .mr-2 {
    margin-right: 16px !important;
  }

  /** Margin start **/
  .ms-0 {
    margin-left: 0 !important;
  }

  .ms-0-75 {
    margin-left: 6px !important;
  }

  .ms-0-5 {
    margin-left: 4px !important;
  }

  .ms-1 {
    margin-left: 8px !important;
  }

  .ms-2 {
    margin-left: 16px !important;
  }

  .ms-3 {
    margin-left: 24px !important;
  }

  .ms-3-75 {
    margin-left: 30px !important;
  }

  .ms-4 {
    margin-left: 32px !important;
  }

  .ms-4-8 {
    margin-left: 48px !important;
  }

  .ms-5 {
    margin-left: 40px !important;
  }

  .ms-5-25 {
    margin-left: 42px !important;
  }

  .ms-6 {
    margin-left: 56px !important;
  }

  /** Margin end **/
  .me-0 {
    margin-right: 0 !important;
  }

  .me-0-5 {
    margin-right: 4px !important;
  }

  .me-0-75 {
    margin-right: 6px !important;
  }

  .me-1 {
    margin-right: 8px !important;
  }

  .me-2 {
    margin-right: 16px !important;
  }

  .me-3 {
    margin-right: 24px !important;
  }

  .me-4 {
    margin-right: 32px !important;
  }

  .me-4-8 {
    margin-right: 48px;
  }

  .me-5 {
    margin-right: 40px !important;
  }

  .me-6-25 {
    margin-right: 50px !important;
  }

  .me-7 {
    margin-right: 56px !important;
  }

  .me-10 {
    margin-right: 80px !important;
  }

  /** Margin X **/
  .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-0-5 {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mx-1 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mx-1-5 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .mx-2 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .mx-3 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .mx-3-25 {
    margin-left: 26px !important;
    margin-right: 26px !important;
  }

  .mx-4 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }

  .mx-5 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mx-6 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }

  /** Margin Y **/
  .my-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .my-1 {
    margin-bottom: 8px !important;
    margin-top: 8px !important;
  }

  .my-1-5 {
    margin-bottom: 12px !important;
    margin-top: 12px !important;
  }

  .my-2 {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
  }

  .my-3 {
    margin-bottom: 24px !important;
    margin-top: 24px !important;
  }

  .my-4 {
    margin-bottom: 32px !important;
    margin-top: 32px !important;
  }

  .my-5 {
    margin-bottom: 40px !important;
    margin-top: 40px !important;
  }

  .my-6 {
    margin-bottom: 56px !important;
    margin-top: 56px !important;
  }

  /** Margin **/
  .m-0 {
    margin: 0 !important;
  }

  .m-1 {
    margin: 8px !important;
  }

  .m-2 {
    margin: 16px !important;
  }

  .m-3 {
    margin: 24px !important;
  }

  .m-4 {
    margin: 32px !important;
  }

  .m-5 {
    margin: 40px !important;
  }

  .m-5 {
    margin: 56px !important;
  }

  /** Margin Medium Breakpoint **/
  @include m.on-view-min(lg) {
    .m-lg-0 {
      margin: 0 !important;
    }

    .m-lg-1 {
      margin: 8px !important;
    }

    .m-lg-2 {
      margin: 16px !important;
    }

    .m-lg-3 {
      margin: 24px !important;
    }

    .m-lg-4 {
      margin: 32px !important;
    }

    .m-lg-5 {
      margin: 40px !important;
    }

    .m-lg-6 {
      margin: 56px !important;
    }
  }

  /** Margin Left Medium Breakpoint **/
  @include m.on-view-min(lg) {
    .ms-lg-0 {
      margin-left: 0 !important;
    }

    .ms-lg-1 {
      margin-left: 8px !important;
    }

    .ms-lg-2 {
      margin-left: 16px !important;
    }

    .ms-lg-3 {
      margin-left: 24px !important;
    }

    .ms-lg-4 {
      margin-left: 32px !important;
    }

    .ms-lg-5 {
      margin-left: 40px !important;
    }

    .ms-lg-6 {
      margin-left: 56px !important;
    }
  }

  /** Margin Right Medium Breakpoint **/
  @include m.on-view-min(lg) {
    .me-lg-0 {
      margin-right: 0 !important;
    }

    .me-lg-1 {
      margin-right: 8px !important;
    }

    .me-lg-2 {
      margin-right: 16px !important;
    }

    .me-lg-3 {
      margin-right: 24px !important;
    }

    .me-lg-4 {
      margin-right: 32px !important;
    }

    .me-lg-5 {
      margin-right: 40px !important;
    }

    .me-lg-6 {
      margin-right: 56px !important;
    }
  }

  /** Margin Top Medium Breakpoint **/
  @include m.on-view-min(lg) {
    .mt-lg-0 {
      margin-top: 0 !important;
    }

    .mt-lg-1 {
      margin-top: 8px !important;
    }

    .mt-lg-2 {
      margin-top: 16px !important;
    }

    .mt-lg-3 {
      margin-top: 24px !important;
    }

    .mt-lg-3-5 {
      margin-top: 28px !important;
    }

    .mt-lg-4 {
      margin-top: 32px !important;
    }

    .mt-lg-4-5 {
      margin-top: 36px !important;
    }

    .mt-lg-5 {
      margin-top: 40px !important;
    }

    .mt-lg-6 {
      margin-top: 56px !important;
    }
  }

  /** Margin Bottom Medium Breakpoint **/
  @include m.on-view-min(lg) {
    .mb-lg-0 {
      margin-bottom: 0 !important;
    }

    .mb-lg-1 {
      margin-bottom: 8px !important;
    }

    .mb-lg-2 {
      margin-bottom: 16px !important;
    }

    .mb-lg-3 {
      margin-bottom: 24px !important;
    }

    .mb-lg-4 {
      margin-bottom: 32px !important;
    }

    .mb-lg-5 {
      margin-bottom: 40px !important;
    }

    .mb-lg-6 {
      margin-bottom: 56px !important;
    }
  }

  /** Margin Y Medium Breakpoint **/
  @include m.on-view-min(lg) {
    .my-lg-0 {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }

    .my-lg-1 {
      margin-bottom: 8px !important;
      margin-top: 8px !important;
    }

    .my-lg-2 {
      margin-bottom: 16px !important;
      margin-top: 16px !important;
    }

    .my-lg-3 {
      margin-bottom: 24px !important;
      margin-top: 24px !important;
    }

    .my-lg-4 {
      margin-bottom: 32px !important;
      margin-top: 32px !important;
    }

    .my-lg-5 {
      margin-bottom: 40px !important;
      margin-top: 40px !important;
    }

    .my-lg-6 {
      margin-bottom: 56px !important;
      margin-top: 56px !important;
    }
  }

  /** Margin X Medium Breakpoint **/
  @include m.on-view-min(lg) {
    .mx-lg-0 {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .mx-lg-1 {
      margin-left: 8px !important;
      margin-right: 8px !important;
    }

    .mx-lg-2 {
      margin-left: 16px !important;
      margin-right: 16px !important;
    }

    .mx-lg-3 {
      margin-left: 24px !important;
      margin-right: 24px !important;
    }

    .mx-lg-4 {
      margin-left: 32px !important;
      margin-right: 32px !important;
    }

    .mx-lg-6 {
      margin-bottom: 56px !important;
      margin-top: 56px !important;
    }
  }

  // PADDINGS
  /** Padding top **/
  .pt-0 {
    padding-top: 0 !important;
  }

  .pt-0-5 {
    padding-top: 4px !important;
  }

  .pt-1 {
    padding-top: 8px !important;
  }

  .pt-1-4 {
    padding-top: 10px !important;
  }

  .pt-1-5 {
    padding-top: 12px !important;
  }

  .pt-2 {
    padding-top: 16px !important;
  }

  .pt-2-25 {
    padding-top: 18px !important;
  }

  .pt-2-5 {
    padding-top: 20px !important;
  }

  .pt-3 {
    padding-top: 24px !important;
  }

  .pt-3-25 {
    padding-top: 26px !important;
  }

  .pt-3-5 {
    padding-top: 28px !important;
  }

  .pt-4 {
    padding-top: 32px !important;
  }

  .pt-4-5 {
    padding-top: 36px !important;
  }

  .pt-5 {
    padding-top: 40px !important;
  }

  .pt-6 {
    padding-top: 48px !important;
  }

  .pt-7 {
    padding-top: 56px !important;
  }

  /** Padding bottom **/
  .pb-0 {
    padding-bottom: 0 !important;
  }

  .pb-1 {
    padding-bottom: 8px !important;
  }

  .pb-1-5 {
    padding-bottom: 12px !important;
  }

  .pb-1-75 {
    padding-bottom: 14px !important;
  }

  .pb-2 {
    padding-bottom: 16px !important;
  }

  .pb-2-5 {
    padding-bottom: 20px !important;
  }

  .pb-3 {
    padding-bottom: 24px !important;
  }

  .pb-3-5 {
    padding-bottom: 28px !important;
  }

  .pb-4 {
    padding-bottom: 32px !important;
  }

  .pb-5 {
    padding-bottom: 40px !important;
  }

  .pb-6-75 {
    padding-bottom: 54px !important;
  }

  /** Padding start **/
  .ps-0 {
    padding-left: 0 !important;
  }

  .ps-0-5 {
    padding-left: 4px !important;
  }

  .ps-1 {
    padding-left: 8px !important;
  }

  .ps-2 {
    padding-left: 16px !important;
  }

  .ps-3 {
    padding-left: 24px !important;
  }

  .ps-4 {
    padding-left: 32px !important;
  }

  .ps-5 {
    padding-left: 40px !important;
  }

  .ps-7 {
    padding-left: 56px !important;
  }

  /** Padding end **/
  .pe-0 {
    padding-right: 0 !important;
  }

  .pe-1 {
    padding-right: 8px !important;
  }

  .pe-2 {
    padding-right: 16px !important;
  }

  .pe-3 {
    padding-right: 24px !important;
  }

  .pe-4 {
    padding-right: 32px !important;
  }

  .pe-5 {
    padding-right: 40px !important;
  }

  /** Padding X **/
  .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-1 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .px-1-5 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .px-2 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .px-3 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .px-3-25 {
    padding-left: 26px !important;
    padding-right: 26px !important;
  }

  .px-4 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .px-4-8 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }

  .px-5 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .px-5-5 {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }

  .px-6 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }

  .px-7 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }

  /** Padding Y **/
  .py-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .py-1 {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
  }

  .py-1-5 {
    padding-bottom: 12px !important;
    padding-top: 12px !important;
  }

  .py-2 {
    padding-bottom: 16px !important;
    padding-top: 16px !important;
  }

  .py-3 {
    padding-bottom: 24px !important;
    padding-top: 24px !important;
  }

  .py-3-5 {
    padding-bottom: 28px !important;
    padding-top: 28px !important;
  }

  .py-4 {
    padding-bottom: 32px !important;
    padding-top: 32px !important;
  }

  .py-5 {
    padding-bottom: 40px !important;
    padding-top: 40px !important;
  }

  /** Padding **/
  .p-0 {
    padding: 0 !important;
  }

  .p-1 {
    padding: 8px !important;
  }

  .p-2 {
    padding: 16px !important;
  }

  .p-3 {
    padding: 24px !important;
  }

  .p-4 {
    padding: 32px !important;
  }

  .p-5 {
    padding: 40px !important;
  }

  .pl-0 {
    padding-left: 0 !important;
  }

  .pl-1 {
    padding-left: 8px !important;
  }

  .pl-1-2 {
    padding-left: 12px !important;
  }

  .pl-2 {
    padding-left: 16px !important;
  }

  .pl-3 {
    padding-left: 24px !important;
  }

  .pl-4 {
    padding-left: 32px !important;
  }

  .pl-4-8 {
    padding-left: 48px !important;
  }

  .pl-5 {
    padding-left: 40px !important;
  }

  .pl-6 {
    padding-left: 56px !important;
  }

  .pr-0 {
    padding-right: 0 !important;
  }

  .pr-1 {
    padding-right: 8px !important;
  }

  .pr-1-2 {
    padding-right: 12px !important;
  }

  .pr-2 {
    padding-right: 16px !important;
  }

  .pr-3 {
    padding-right: 24px !important;
  }

  .pr-4 {
    padding-right: 32px !important;
  }

  .pr-4-8 {
    padding-right: 48px !important;
  }

  .pr-5 {
    padding-right: 40px !important;
  }

  .pr-6 {
    padding-right: 48px !important;
  }

  /** Padding Medium Breakpoint **/
  @include m.on-view-min(lg) {
    .p-lg-0 {
      padding: 0 !important;
    }

    .p-lg-1 {
      padding: 8px !important;
    }

    .p-lg-2 {
      padding: 16px !important;
    }

    .p-lg-3 {
      padding: 24px !important;
    }

    .p-lg-4 {
      padding: 32px !important;
    }

    .p-lg-5 {
      padding: 40px !important;
    }
  }

  /** Padding Left Medium Breakpoint **/
  @include m.on-view-min(lg) {
    .ps-lg-0 {
      padding-left: 0 !important;
    }

    .ps-lg-1 {
      padding-left: 8px !important;
    }

    .ps-lg-2 {
      padding-left: 16px !important;
    }

    .ps-lg-3 {
      padding-left: 24px !important;
    }

    .ps-lg-4 {
      padding-left: 32px !important;
    }

    .ps-lg-5 {
      padding-left: 40px !important;
    }
  }

  /** Padding Right Medium Breakpoint **/
  @include m.on-view-min(lg) {
    .pe-lg-0 {
      padding-right: 0 !important;
    }

    .pe-lg-1 {
      padding-right: 8px !important;
    }

    .pe-lg-2 {
      padding-right: 16px !important;
    }

    .pe-lg-3 {
      padding-right: 24px !important;
    }

    .pe-lg-4 {
      padding-right: 32px !important;
    }

    .pe-lg-5 {
      padding-right: 40px !important;
    }
  }

  /** Padding Top Medium Breakpoint **/
  @include m.on-view-min(lg) {
    .pt-lg-0 {
      padding-top: 0 !important;
    }

    .pt-lg-1 {
      padding-top: 8px !important;
    }

    .pt-lg-2 {
      padding-top: 16px !important;
    }

    .pt-lg-3 {
      padding-top: 24px !important;
    }

    .pt-lg-4 {
      padding-top: 32px !important;
    }

    .pt-lg-5 {
      padding-top: 40px !important;
    }
  }

  /** Padding Bottom Medium Breakpoint **/
  @include m.on-view-min(lg) {
    .pb-lg-0 {
      padding-bottom: 0 !important;
    }

    .pb-lg-1 {
      padding-bottom: 8px !important;
    }

    .pb-lg-2 {
      padding-bottom: 16px !important;
    }

    .pb-lg-3 {
      padding-bottom: 24px !important;
    }

    .pb-lg-4 {
      padding-bottom: 32px !important;
    }

    .pb-lg-5 {
      padding-bottom: 40px !important;
    }
  }

  /** Padding Y Medium Breakpoint **/
  @include m.on-view-min(lg) {
    .py-lg-0 {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }

    .py-lg-0-5 {
      padding-bottom: 4px !important;
      padding-top: 4px !important;
    }

    .py-lg-1 {
      padding-bottom: 8px !important;
      padding-top: 8px !important;
    }

    .py-lg-2 {
      padding-bottom: 16px !important;
      padding-top: 16px !important;
    }

    .py-lg-3 {
      padding-bottom: 24px !important;
      padding-top: 24px !important;
    }

    .py-lg-4 {
      padding-bottom: 32px !important;
      padding-top: 32px !important;
    }

    .py-lg-5 {
      padding-bottom: 40px !important;
      padding-top: 40px !important;
    }
  }

  /** Padding X Medium Breakpoint **/
  @include m.on-view-min(lg) {
    .px-lg-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .px-lg-1 {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }

    .px-lg-2 {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }

    .px-lg-3 {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }

    .px-lg-4 {
      padding-left: 32px !important;
      padding-right: 32px !important;
    }

    .px-lg-5 {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
  }

  .h-100 {
    height: 100% !important;
  }

  .w-min-content {
    width: min-content !important;
  }

  .w-100 {
    width: 100% !important;
  }

  .h-75 {
    height: 75% !important;
  }

  .w-85 {
    width: 85% !important;
  }

  .w-80 {
    width: 80% !important;
  }

  .w-75 {
    width: 75% !important;
  }

  .w-70 {
    width: 70% !important;
  }

  .w-60 {
    width: 60% !important;
  }

  .w-30 {
    width: 30% !important;
  }

  .w-20 {
    width: 20% !important;
  }

  .w-15 {
    width: 15% !important;
  }

  .h-50 {
    height: 50% !important;
  }

  .w-50 {
    width: 50% !important;
  }

  .w-40 {
    width: 40% !important;
  }

  .w-30 {
    width: 30% !important;
  }

  .w-25 {
    width: 25% !important;
  }

  .h-25 {
    height: 25% !important;
  }

  .h-xx-small {
    height: var(--ion-height-xx-small) !important;
  }

  .h-x-small {
    height: var(--ion-height-x-small) !important;
  }

  .h-small {
    height: var(--ion-height-small) !important;
  }

  .h-medium {
    height: var(--ion-height-medium) !important;
  }

  .h-large {
    height: var(--ion-height-large) !important;
  }

  // From mobile size
  .d-block {
    display: block !important;
  }

  .d-none {
    display: none !important;
  }

  .d-flex {
    display: flex !important;
  }

  .flex-column {
    flex-direction: column !important;
  }

  .flex-row {
    flex-direction: row !important;
  }

  .p-relative {
    position: relative !important;
  }

  .p-absolute {
    position: absolute !important;
  }

  .bottom-0 {
    bottom: 0 !important;
  }

  .left-0 {
    left: 0 !important;
  }

  .right-0 {
    right: 0 !important;
  }

  .top-0 {
    top: 0 !important;
  }

  .ion-align-items-start {
    text-align: left !important;
  }

  .ion-align-items-end {
    text-align: right !important;
  }

  .z-1 {
    z-index: 1;
  }

  .z-2 {
    z-index: 2;
  }

  .z-3 {
    z-index: 3;
  }

  .z-10 {
    z-index: 10;
  }

  .overflow-y-auto {
    overflow-y: auto;
  }

  .overflow-y-scroll {
    overflow-y: scroll;
  }

  .overflow-y-hidden {
    overflow-y: hidden;
  }

  // From Desktop size
  @include m.on-view-min(lg) {
    .h-lg-100 {
      height: 100% !important;
    }

    .w-lg-100 {
      width: 100% !important;
    }

    .h-lg-75 {
      height: 75% !important;
    }

    .w-lg-75 {
      width: 75% !important;
    }

    .h-lg-50 {
      height: 50% !important;
    }

    .w-lg-50 {
      width: 50% !important;
    }

    .w-lg-35 {
      width: 35% !important;
    }

    .h-lg-25 {
      height: 25% !important;
    }

    .w-lg-25 {
      width: 25% !important;
    }

    .d-lg-none {
      display: none !important;
    }

    .d-lg-block {
      display: block !important;
    }

    .d-lg-flex {
      display: flex !important;
    }

    .flex-lg-column {
      flex-direction: column !important;
    }

    .flex-lg-row {
      flex-direction: row !important;
    }

    .ion-align-items-lg-center {
      align-items: center !important;
    }

    .ion-align-items-lg-start {
      align-items: start !important;
    }

    .ion-align-items-lg-end {
      align-items: end !important;
    }

    .ion-justify-content-lg-center {
      justify-content: center !important;
    }

    .ion-justify-content-lg-start {
      justify-content: start !important;
    }

    .ion-justify-content-lg-end {
      justify-content: end !important;
    }

    .ion-justify-content-lg-between {
      justify-content: space-between !important;
    }

    .ion-justify-content-lg-around {
      justify-content: space-around !important;
    }

    .ion-justify-content-lg-evenly {
      justify-content: space-evenly !important;
    }

    .ion-align-items-lg-start {
      text-align: left !important;
    }

    .ion-align-items-lg-end {
      text-align: right !important;
    }
  }

  .rounded-circle {
    border-radius: 50% !important;
  }

  .opacity-50 {
    opacity: 0.5 !important;
  }
}
